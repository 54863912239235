input[type='radio'],
input[type='range'] {
  cursor: pointer;
  accent-color: var(--c-text);
}

input:focus-visible {
  outline: 0;
  box-shadow: none;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.input-container,
.input-container-text,
.dropdown-input-container {
  @apply border border-grey-200 focus-within:border-grey-900 dark:border-grey-900 focus-within:dark:border-grey-300 rounded-md;
}

input,
.input-container-text,
.dropdown-input-container {
  @apply bg-grey-200 dark:bg-grey-900;
}

.repay-input {
  @apply font-normal px-4 py-2 my-1;
}

.quote-input {
  @apply font-normal px-4 py-2 dark:bg-grey-800;
}

.quote-input input {
  @apply bg-grey-200 dark:bg-grey-800;
}

.card input,
.card .input-container-text {
  background-color: var(--c-bg);
}

.dropdown-input-container,
.input-container {
  border: 1px solid var(--color-grey-15);
}

.dropdown-input-container input {
  width: 92%;
}

[data-theme='dark'] .card {
  background-color: var(--c-bg);
}

.asset-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.asset-inputs.input-currency:last-child {
  justify-content: end;
}

.asset-inputs.input-currency > .input {
  width: 90%;
}

[data-theme='dark'] .input__text {
  border-color: var(--color-grey);
}

.input {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.input[data-disabled] {
  cursor: default;
}

.input__text {
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  padding-bottom: 0.25rem;
  border: 1px solid var(--color-grey-15);
}
.input__text input {
  margin-right: 5px;
}

.input___text > svg {
  width: 8px;
  height: auto;
  color: var(--color-black-2);
}

.input__text-content {
  display: flex;
  align-items: center;
  min-height: 36px;
}
.input__text-content img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 100px;
}

.input-text {
  border-radius: 0.5rem;
}

.input-text input {
  height: 100%;
  border-radius: 0.5rem;
}

.switcher div {
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
}

.switcher div .active {
  color: var(--color-white);
  background-color: var(--color-black);
}

.switcher div :not(.active) {
  color: var(--color-black);
  background-color: var(--color-white);
}

.indicator-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}

.indicator-separator {
  align-self: stretch;
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

[data-theme='dark'] .indicator-separator {
  background-color: var(--color-grey);
}

.indicator-item {
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: hsl(0, 0%, 80%);
  padding: 8px;
  box-sizing: border-box;
}

[data-theme='dark'] .indicator-item {
  color: var(--color-grey);
}

.indicator-item:hover {
  color: hsl(0, 0%, 60%);
}

[data-theme='dark'] .switcher div {
  background-color: var(--color-black);
  border: 1px solid var(--color-grey-14);
}

[data-theme='dark'] .switcher div .active {
  color: var(--color-black);
  background-color: var(--color-white);
}

[data-theme='dark'] .switcher div :not(.active) {
  color: var(--color-white);
  background-color: var(--color-black);
}
