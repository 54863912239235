@tailwind utilities;

.card {
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: var(--color-grey-25);
}

[data-theme='dark'] .card.borrow-card,
[data-theme='dark'] .card {
  background-color: var(--c-bg);
}

.card.borrow-card {
  padding: 1.25rem 1.25rem;
  background-color: var(--color-white);
}

.borrow-card p,
.borrow-card b,
.borrow-card span {
  @apply text-xs;
}
