@tailwind utilities;

.status > span {
  @apply text-xs px-2 py-1;
}

.status > span[data-status='green'] {
  @apply rounded-lg text-green-dark bg-green-light dark:text-green-light dark:bg-green-dark;
}

.status > span[data-status='red'] {
  @apply rounded-lg text-red-dark bg-red-light dark:text-red-light dark:bg-red-dark;
}

.status > span[data-status='yellow'] {
  @apply rounded-lg text-yellow-500 bg-yellow-100 dark:text-yellow-100 dark:bg-yellow-500;
}

.status > span[data-status='blue'] {
  @apply rounded-lg text-blue-dark bg-blue-light dark:text-blue-light dark:bg-blue-dark;
}
