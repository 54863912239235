.item-hints {
  display: inline-block;
  vertical-align: text-top;
}

.item-hints .hint {
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.items-hints .hint::before {
  background-color: var(--c-bg);
  border-radius: 8px;
  content: ' ';
  display: flex;
  height: 16px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 16px;
}

.item-hints .hint-content {
  color: var(--c-text);
  width: 300px;
  position: absolute;
  border-radius: 6px;
  z-index: 5;
  padding: 12px 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition:
    opacity 0.7s ease,
    visibility 0.7s ease;
  transition:
    opacity 0.7s ease,
    visibility 0.7s ease;
}

.item-hints .hint:hover .hint-content {
  color: var(--c-text);
  background-color: var(--c-bg);
  width: 300px;
  position: absolute;
  z-index: 5;
  padding: 12px 0;
  opacity: 1;
  visibility: visible !important;
  -webkit-transition:
    opacity 0.7s ease,
    visibility 0.7s ease;
  transition:
    opacity 0.7s ease,
    visibility 0.7s ease;
}

.item-hints .hint-content::before {
  width: 0px;
  bottom: 0;
  left: 0;
  content: '';
  background-color: var(--c-text);
  height: 1px;
  position: absolute;
  transition: width 0.4s;
}

.item-hints .hint-content p::first-letter {
  text-transform: uppercase;
}

.item-hints .hint:hover .hint-content::before {
  width: 180px;
  transition: width 0.4s;
}

.item-hints .hint-content::after {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
  bottom: 0;
  left: 0;
  width: 40px;
  content: '';
  background-color: var(--c-text);
  height: 1px;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.item-hints .hint:hover .hint-content::after {
  opacity: 1;
  visibility: visible;
}

.item-hints .hint[data-position='4'] .hint-content {
  bottom: 40px;
  left: 50%;
  margin-left: 32px;
  padding: 0.5rem;
}
