@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 50%);
  content: ' ';
}

.dropdown__target {
  width: 100%;
  font-size: inherit;
  background: none;
  border: none;
}

.dropdown__target:focus {
  outline: none;
}

.dropdown__header {
  display: flex;
  justify-content: flex-end;
  padding: 13px;
  padding-bottom: 0;
}

.dropdown__header button {
  display: flex;
  background: none;
  border: none;
}

.dropdown__header button:focus {
  outline: none;
}
