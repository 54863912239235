@tailwind utilities;

[data-theme='dark'] .header__top > span {
  color: var(--color-grey-2);
}

[data-theme='dark'] .header__top > button {
  color: var(--color-white);
}

[data-theme='dark'] .overlay {
  background: rgba(11, 15, 16, 0.5);
}

[data-theme='dark'] .modal {
  @apply bg-grey-800;
}

[data-theme='dark'] .coin__input {
  color: var(--color-grey-3);
  background-color: var(--color-black);
  border-color: var(--color-grey-10);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(11, 15, 16, 0.2);
  backdrop-filter: blur(1.5px);
}

.modal {
  @apply rounded-xl;
  @apply w-120;
  @apply p-6;

  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: auto;
  background-color: var(--color-white);
}

.modal:focus {
  outline: none;
}

.repay-modal {
  width: 100%;
  max-width: 20rem;
}

.modal__footer {
  padding: 14px 26px;

  @apply text-xs;
}

.coin {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.coin:last-of-type {
  margin-bottom: 14px;
}

.coin__label {
  margin-bottom: 6px;

  @apply text-xs;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 12px 13px 12px 24px;
  border-bottom: 1px solid var(--color-grey-8);
}

.header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}

.header__top > span {
  color: var(--color-grey);
  @apply text-xs;
}

.header__top > button {
  display: flex;
  background: none;
  border: none;
}
.header__top > button svg {
  width: 14.2px;
  height: auto;
}

.header__bottom {
  @apply text-sm;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: scale(1.2);
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: scale(1);
  opacity: 1;
}

.ReactModal__Content--before-close {
  transform: scale(1.2);
  opacity: 0;
}
