[data-theme='dark'] .banner {
  background-color: var(--color-black-2);
}

.banner {
  position: relative;
  margin-bottom: 15px;
  background-color: var(--color-grey-25);
}

.banner::before {
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 8%), transparent, rgba(0, 0, 0, 8%));
  content: ' ';
  inset: 0;
}

.banner > * {
  z-index: 0;
}

.bg {
  position: absolute;
  overflow: hidden;
  inset: 0;

  margin-top: -1.25rem;
  margin-left: -3.75rem;
  margin-right: -3.75rem;
  background-color: var(--c-bg);
}

.bg svg {
  position: absolute;
  right: 0;
}
