.merge-icons > svg:nth-child(2),
.merge-icons > img:nth-child(2) {
  margin-left: -5px;
}

.borrow .merge-icons svg,
.borrow .merge-icons img {
  z-index: 2;
  transform: scale(0.75);
}

.borrow .merge-icons > svg:nth-child(2),
.borrow .merge-icons > img:nth-child(2) {
  z-index: 1;
  margin-left: -10px;
}

.clickable:hover {
  cursor: pointer;
  opacity: 0.6;
}

.unread.:after {
  content: '';
  display: block;
  position: absolute;
  width: 9px;
  height: 9px;
  background: var(--color-v2-black);
  top: 0;
  right: -1px;
  border-radius: 50%;
  border: 1px var(--color-v2-white) solid;
}
