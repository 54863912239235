@font-face {
  font-weight: 400;
  font-family: 'Neue Haas Grotesk';
  src: url('../assets/fonts/NHaasGroteskTXPro-45RroLight.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'Neue Haas Grotesk';
  src: url('../assets/fonts/NHaasGroteskTXPro-65Md.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'Neue Haas Grotesk';
  src: url('../assets/fonts/NHaasGroteskTXPro-75Bd.ttf') format('truetype');
}
