@import './fonts.css';
@import './tooltip.css';
@import './toast.css';
@import './inputs.css';
@import './icons.css';
@import './table.css';
@import './card.css';
@import './banner.css';
@import './button.css';
@import './header.css';
@import './loading.css';
@import './modal.css';
@import './dropdown.css';
@import './label.css';
@import './tabs.css';
@import './liquidity.css';
@import './variables.css';
@import './logo.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--font-neue-haas-grotesk);
  font-weight: 500;
}

body {
  font-family: var(--font-neue-haas-grotesk);
  font-weight: 500;
}

#root {
  width: 100%;
  min-width: 680px;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

html,
body {
  height: 100%;
}

html {
  font-size: 100%;
  color: var(--c-text);
  background-color: var(--c-bg);
}

body {
  @apply text-sm;
  letter-spacing: 0.14px;
}

[data-theme='dark'] body {
  color: var(--color-v2-white);
}

main {
  @apply md:px-15 px-2 pb-[4.688rem] pt-5;
}

.bg-airdrop {
  padding-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/background.svg');
  opacity: 1;
  transition: opacity 0.5s ease;
}

[data-theme='dark'] .bg-airdrop {
  background-image: url('/background-white.svg');
}

.bg-airdrop.airdrop {
  height: calc(100vh - 72px);
}

.bg-lines {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/lines.svg');
}

.sand-clock path {
  fill: #439cc2;
}

[data-theme='dark'] .sand-clock path {
  fill: #e2f2f8;
}

.header-height {
  height: 72px;
}

.body-height {
  min-height: calc(100vh - 72px - 4.688rem - 1.25rem - 7rem); /** manage loans **/
}

.full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-container > * {
  width: 100%;
}

.hero-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1172px;
  margin: 0 auto;
  padding: 16px;
  padding-top: 55px;
  padding-bottom: 55px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  padding: 14px 25px 25px;
  border-bottom: 1px solid var(--color-grey-8);
}

.overlay-dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 11;
  min-width: 180px;
  margin-top: 15px;
  overflow: hidden;
  background-color: var(--c-bg);
  border-radius: 10px;
  box-shadow: 0 5px 20px 10px rgba(0, 0, 0, 10%);
}

[data-theme='dark'] .shadow,
[data-theme='dark'] .bg-white {
  background-color: var(--color-black-2);
  color: var(--cl-bg);
}

[data-theme='dark'] .shadow-lg {
  box-shadow:
    0 10px 15px -3px rgb(255 255 255 / 0.1),
    0 4px 6px -4px rgb(255 255 255 / 0.1);
}

.shadow-lg {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.shadow {
  box-shadow: 0 0px 25px 0px rgba(0, 0, 0, 0.4);
}

.shadow-v2 {
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
}

.shadow-v2-header {
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.07);
}

.shadow-v2-body {
  box-shadow: 0px 10px 40px 0px rgba(85, 85, 102, 0.05);
}

.shadow-v2-borrow {
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05);
}

.shadow-v2-dropdown {
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
}

.lp-content {
  padding: 14px;
}

.lp-content > * + * {
  margin-top: 15px;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 110px);
}

.theme-bg-color {
  background-color: var(--c-bg);
}

.border-gap {
  --b: 2px;
  --c: #d64b4b;
  --w: 10px;

  border: var(--b) solid #0000;
  border-radius: 5px;
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background:
    conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
}

.theme-text-color {
  background-color: var(--c-text);
}

.circle {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-weight: bold;
  border-radius: 100px;
}

.outside-circle {
  @apply border border-black dark:border-v2-white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;
  margin-right: 4px;
}

[data-disabled] {
  pointer-events: none;
}

[data-theme='dark'] .home-hero-bg {
  background-color: var(--color-black-2);
  color: var(--cl-bg);
}

.home-hero-bg {
  inset: 0;
  position: absolute;
  margin-left: auto;
  overflow: hidden;
}

.home-hero-bg svg {
  position: absolute;
}

.grey-17-bg-color {
  background-color: var(--color-grey-17);
}

.grey-3-bg-color {
  background-color: var(--color-grey-3);
}

.grey-1-bg-color {
  background-color: var(--color-grey-1);
}

.grey-3-color {
  color: var(--color-grey-3);
}

.blur-table {
  filter: blur(16px);
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.blur-table > tr:not(:first-child),
.blur-table .border-2 {
  border: 0 !important;
}

.blur-card {
  filter: blur(2px);
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.blur-card > tr:not(:first-child),
.blur-card .border-2 {
  border: 0 !important;
}

.empty-table-message {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 8rem;
}

.empty-table-message__pool {
  width: 11.6rem;
}

.empty-table-message__bucket {
  width: 9.3rem;
}

.empty-table-message__loan-lp {
  width: 11.1rem;
}

.empty-table-message__open-loan,
.empty-table-message__past-loan {
  width: 13.6rem;
}

div.left-bg svg {
  left: 0;
}

.blur-card {
  filter: blur(6px);
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.empty-card-message {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @apply text-xl;
}

.empty-card-message__liquidity {
  top: 6rem;
}

.empty-card-message__form {
  bottom: 12.5rem;
}

.own-risk-banner ~ .body-height,
.own-risk-banner ~ .body-height .body-height {
  min-height: calc(100vh - 140px) !important;
}

.ReactCollapse--collapse {
  transition: height 50ms;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle-container svg {
  transform: rotate(-90deg);
  stroke: var(--color-grey-400);
}

[data-theme='dark'] .circle-container svg {
  stroke: var(--color-grey-600);
}
