.logo-container.svelte-892t24 {
  border-radius: 50%;
  height: 70vw;
  margin: 0 auto;
  position: relative;
  width: 70vw;
}

.logo.svelte-892t24 {
  border-radius: 50%;
  border-style: solid;
  position: absolute;
  top: 0;
}

.logo__outer--thin.svelte-892t24 {
  -webkit-animation: svelte-892t24-logoOrbit 15s ease-in-out infinite;
  animation: svelte-892t24-logoOrbit 15s ease-in-out infinite;
  border-color: #0000001a;
  border-width: 1;
  height: 100%;
  width: 100%;
}

.logo__outer--thin.svelte-892t24:after {
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.15;
  position: absolute;
  right: 0;
  top: 0;
}

.logo__outer--thick.svelte-892t24 {
  -webkit-animation: svelte-892t24-logoRotate 15s ease-in-out infinite;
  animation: svelte-892t24-logoRotate 15s ease-in-out infinite;
  border-width: 2px;
  height: 100%;
  transform: rotate(-45deg);
  width: 100%;
}

.logo__orbit.svelte-892t24 {
  border-width: 2px;
  height: 2vw;
  right: 21vw;
  top: 1.5vw;
  width: 2vw;
}

.logo__orbit.svelte-892t24:before {
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 0.6vw;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.6vw;
}

@-webkit-keyframes svelte-892t24-logoRotate {
  0% {
    transform: rotate(-45deg);
  }

  to {
    transform: rotate(-405deg);
  }
}

@keyframes svelte-892t24-logoRotate {
  0% {
    transform: rotate(-45deg);
  }

  to {
    transform: rotate(-405deg);
  }
}

@-webkit-keyframes svelte-892t24-logoOrbit {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes svelte-892t24-logoOrbit {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.logo-container.svelte-1haokad {
  border-radius: 50%;
  height: 160x;
  margin: 0 auto;
  position: relative;
  width: 160px;
}

.logo.svelte-1haokad {
  border-radius: 50%;
  border-style: solid;
  position: absolute;
  top: 0;
}

.logo__outer--thin.svelte-1haokad {
  -webkit-animation: svelte-1haokad-logoOrbit 15s ease-in-out infinite;
  animation: svelte-1haokad-logoOrbit 15s ease-in-out infinite;
  border-color: #0000001a;
  border-width: 1px;
  height: 100%;
  width: 100%;
}

.logo__outer--thin.svelte-1haokad:after {
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.15;
  position: absolute;
  right: 0;
  top: 0;
}

.logo__outer--thick.svelte-1haokad {
  -webkit-animation: svelte-1haokad-logoRotate 15s ease-in-out infinite;
  animation: svelte-1haokad-logoRotate 15s ease-in-out infinite;
  border-width: 2px;
  height: 99.5%;
  transform: rotate(-45deg);
  width: 99.5%;
}

.logo__orbit.svelte-1haokad {
  border-width: 1px;
  height: 0.7rem;
  right: 1.2rem;
  top: 1rem;
  width: 0.7rem;
}

.logo__orbit.svelte-1haokad:before {
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 0.3rem;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.3rem;
}

@-webkit-keyframes svelte-1haokad-logoRotate {
  0% {
    transform: rotate(-45deg);
  }

  to {
    transform: rotate(-405deg);
  }
}

@keyframes svelte-1haokad-logoRotate {
  0% {
    transform: rotate(-45deg);
  }

  to {
    transform: rotate(-405deg);
  }
}

@-webkit-keyframes svelte-1haokad-logoOrbit {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes svelte-1haokad-logoOrbit {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}
