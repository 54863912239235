@tailwind utilities;

[data-theme='dark'] .header-dropdown__list {
  background: var(--color-black);
}

.header-dropdown__target {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.header-dropdown__target svg {
  display: inline;
}

.header-dropdown__target:hover + .header-dropdown__container {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}

.header-dropdown__container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  min-width: 15rem;
  padding-top: 10px;
  transform: translateY(10px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.header-dropdown__container:hover {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}

.header-dropdown__list {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 5px;
}

.header-dropdown__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  line-height: 1;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.header-dropdown__item[data-disabled] {
  color: var(--color-grey-2);
  background-color: var(--color-grey-1);
  pointer-events: none;
}

.header-dropdown__item::before {
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: var(--color-grey-3);
  border-radius: 100px;
  content: ' ';
}

.header-dropdown__item:hover {
  color: var(--c-bg);
  background-color: var(--c-text);
}

.header-dropdown__item span {
  flex: 1 1 auto;
  line-height: 1.4;
  white-space: pre-wrap;
}

.header-dropdown__item svg {
  flex-shrink: 0;
  height: 20px;
  margin-left: 30px;
}
