@tailwind utilities;

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.prepend-icon {
  @apply w-6;
  @apply mr-2;
  height: auto;
}

.append-icon {
  width: 20px;
  height: auto;
  margin-right: 12px;

  margin-right: 0;
  margin-left: 8px;
}

.icon-button {
  @apply flex;
  @apply w-10;
  @apply h-10;
  @apply rounded-md;
  @apply border;
  @apply border-solid;
  @apply border-v2-black;
  @apply bg-transparent;
  @apply p-1;
  @apply justify-center;
  @apply align-middle;
  @apply items-center;
}

.icon-button:hover {
  @apply bg-grey-300;
}

.icon-button:disabled {
  @apply opacity-30;
}

[data-theme='dark'] .icon-button {
  @apply text-v2-white;
  @apply border-v2-white;
}

[data-theme='dark'] .icon-button:hover {
  @apply bg-grey-700;
}

[data-theme='dark'] .borrow-pair__item {
  border-color: var(--color-grey-14);
}

.borrow-pair__item {
  text-align: center;
  background: none;
  border: 1px solid var(--color-black-2);
  border-radius: 0.5rem;
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}
.borrow-pair__item:hover {
  opacity: 0.6;
}

.borrow-pair__item[data-active='true'] {
  opacity: 1;
}
