.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  padding: 30px;
  border-radius: 5px;
}

.loading__text {
  margin-top: 20px;

  @apply text-lg;
}
