@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-v2-black: #141414;
    --color-v2-light-black: #141414;
    --color-v2-white: #fcfdfd;
    --color-grey-900: #4c5661;
    --color-grey-800: #6e7a87;
    --color-grey-700: #8b98a7;
    --color-grey-600: #abb9c9;
    --color-grey-500: #c7d0db;
    --color-grey-400: #d9dfe7;
    --color-grey-300: #e6eaef;
    --color-grey-200: #f2f4f7;
    --color-grey-100: #f8fafb;
    --color-green-dark: #2c6856;
    --color-green-light: #e2f8ee;
    --color-yellow-dark: #c98f13;
    --color-yellow-light: #fdf1c9;
    --color-red-dark: #d64b4b;
    --color-red-light: #ffd1d1;
    --color-blue-dark: #2c5668;
    --color-blue-light: #e2f2f8;
  }

  :root[data-theme~='dark'] {
    --color-v2-black: #121416;
    --color-v2-light-black: #141414;
    --color-v2-white: #f4f5f6;
    --color-grey-900: #1a1e23;
    --color-grey-800: #292e35;
    --color-grey-700: #333942;
    --color-grey-600: #666a75;
    --color-grey-500: #989daa;
    --color-grey-400: #b9bdc5;
    --color-grey-300: #cdcfd6;
    --color-grey-200: #dbdde1;
    --color-grey-100: #e9eaed;
    --color-green-dark: #203c33;
    --color-green-light: #65bea3;
    --color-yellow-dark: #393323;
    --color-yellow-light: #efcd53;
    --color-red-dark: #86282e;
    --color-red-light: #faafb0;
    --color-blue-dark: #0f3747;
    --color-blue-light: #439cc2;
    --color-grey-dark: #2b3b42;
    --color-grey-light: #869faa;
  }
}

:root,
html[data-theme='light'] {
  color-scheme: light;

  --font-neue-haas-grotesk: 'Neue Haas Grotesk', sans-serif;
  --c-bg: var(--color-v2-white);
  --c-text: var(--color-v2-black);

  --color-grey: #58595b;
  --color-grey-1: #e6e7e8;
  --color-grey-2: #bcbec0;
  --color-grey-3: #6d6e71;
  --color-grey-4: #808285;
  --color-grey-5: #4e4e4e;
  --color-grey-6: #939598;
  --color-grey-7: #565656;
  --color-grey-8: #d1d3d4;
  --color-grey-9: #a7a9ac;
  --color-grey-10: #f1f2f2;
  --color-grey-11: #b0b0b0;
  --color-grey-12: #757575;
  --color-grey-13: #6b6b6b;
  --color-grey-14: #e0e0e0;
  --color-grey-15: #cbcdce;
  --color-grey-16: #808080;
  --color-grey-17: #ebecec;
  --color-grey-18: #a1a3a6;
  --color-grey-19: #f3f3f3;
  --color-grey-20: #c8cacb;
  --color-grey-21: #e8e9e9;
  --color-grey-22: #f1f1f1;
  --color-grey-23: #707070;
  --color-grey-25: #f5f5f5;
  --color-black: #000;
  --color-black-1: #414042;
  --color-black-2: #212121;
  --color-black-3: #0a0a0a;
  --color-black-4: #121212;
  --color-white: #fff;
  --color-green: #2daa65;
  --color-green-1: rgba(45, 170, 101, 15%);
  --color-red: #fd4a4d;
  --color-red-1: rgba(253, 74, 77, 15%);
}

html[data-theme='dark'] {
  color-scheme: dark;

  --c-bg: var(--color-v2-black);
  --c-text: var(--color-v2-white);
}

@media (prefers-color-scheme: dark) {
  :root {
    --c-bg: var(--color-v2-black);
    --c-text: var(--color-v2-white);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --c-bg: var(--color-v2-white);
    --c-text: var(--color-v2-black);
  }
}

/* For browsers that don’t support `color-scheme` and therefore
   don't handle system dark mode for you automatically
   (Firefox), handle it for them. */
@supports not (color-scheme: light dark) {
  html {
    color: var(--c-text);
    background-color: var(--c-bg);
  }
}
