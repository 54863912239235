.toast {
  background-color: var(--c-text) !important;
  color: var(--c-bg) !important;
}

.toast-container {
  top: 9rem !important;
}

.toast a {
  color: var(--c-bg) !important;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 600;
  cursor: pointer;
}
