@tailwind utilities;

.table {
  @apply w-full text-left text-grey-800 dark:text-grey-500 relative border-separate border-spacing-x-0 border-spacing-y-3;
}

.table tbody tr {
  @apply bg-white dark:bg-grey-900 text-base text-left text-v2-black dark:text-v2-white;
}

.table tbody:not(.no-hover) tr:hover {
  @apply bg-grey-200 dark:bg-grey-700 cursor-pointer;
}

.table tbody tr td:first-of-type {
  @apply pl-4 border-l rounded-l-lg;
}

.table tbody tr td {
  @apply border-y border-grey-300 dark:border-grey-700 py-4 overflow-hidden whitespace-nowrap;
}

.borrow .table tbody tr td {
  @apply py-3;
}

.table tbody tr.table-row-active td {
  @apply border-v2-black bg-grey-200 dark:bg-grey-700 dark:border-v2-white;
}

.table tbody tr td:last-of-type {
  @apply border-r rounded-r-lg pr-4;
}

.table p,
.table span {
  @apply font-normal;
}

.borrow .table th,
.borrow .table th div {
  @apply text-xs;
}

.borrow .table td,
.borrow .table div {
  @apply text-sm;
}

.arrow-box {
  display: inline-flex;
  flex-direction: column;
  margin-left: 4px;
}

.arrow-box__up {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 4px 4px;
  border-color: transparent transparent var(--color-grey-700);
  margin-bottom: 2px;
}

.arrow-box__down {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 4px 0px;
  border-color: var(--color-grey-700) transparent transparent;
}

.arrow-box__up__active {
  border-color: transparent transparent var(--color-v2-black);
  opacity: 1;
}

[data-theme='dark'] .arrow-box__up__active {
  border-color: transparent transparent var(--color-v2-white);
}

.arrow-box__down__active {
  border-color: var(--color-v2-black) transparent transparent;
}

[data-theme='dark'] .arrow-box__down__active {
  border-color: var(--color-v2-white) transparent transparent;
}

.cursor-pointer.shadow.rounded-xl,
.cursor-pointer.shadow.rounded-xl + tr {
  border-top-color: transparent;
}
