@tailwind utilities;

[data-theme='dark'] .tape__item {
  color: var(--color-black-1);
  border-bottom-color: var(--color-black-1);
}
[data-theme='dark'] .tape--active {
  color: var(--color-grey-14);
  border-bottom-color: var(--color-grey-14);
}

.tape {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tape::-webkit-scrollbar {
  display: none;
}

.tape__item {
  color: var(--color-grey-2);
  white-space: nowrap;
  background: none;
  border: none;
  border-bottom: 2px solid var(--color-grey-14);

  @apply text-base;
}

.tape--active {
  color: var(--color-black);
  border-bottom-color: var(--color-black);
}
