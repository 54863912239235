@tailwind utilities;

.item {
  display: flex;
}

.item[data-deployed] [data-fill] {
  padding-top: 6px;
}
.item[data-deployed] [data-fill]::after {
  height: 95%;
}

.item[data-claimable] .item__step::before {
  display: none;
}

.item[data-claimable] .item__step::after {
  display: none;
}

.item[data-claimable] [data-fill] {
  padding-top: 6px;
}

.item__step {
  position: relative;
  flex-shrink: 0;
  width: 16px;
  margin-right: 1.3rem;
  padding-top: 20px;
}

.item__step svg {
  position: relative;
  z-index: 2;
  display: block;
  width: 8.5px;
  margin: -9px auto 3px 7.5px;
  color: var(--color-grey-14);
}

.item__step span {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: var(--c-bg);
  font-weight: bold;
  background-color: var(--color-grey-11);
  border-radius: 100px;
  font-size: 0.875rem;
  line-height: 1.25rem;

  @apply text-xs;
}

.item__step[data-fill]::after {
  position: absolute;
  left: 75.6%;
  height: 72%;
  border: 0.02rem solid var(--color-grey-14);
  transform: translateX(-75%);
  content: ' ';
}

.item__content {
  flex: 1 1 auto;
}

[data-theme='dark'] .composition-container {
  background-color: var(--color-black-2);
}

.composition-container {
  position: relative;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 48px;
  margin-top: 1rem;
  overflow: hidden;
  background-color: var(--color-grey-25);
  border-radius: 10px;
}
.composition-container > * {
  z-index: 1;
}

.composition-container::before {
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 8%), transparent, rgba(0, 0, 0, 8%));
  content: ' ';
  inset: 0;
}

[data-theme='dark'] .composition-card {
  background-color: var(--color-black);
}

.chart {
  display: flex;
  align-items: center;
}

.chart-container {
  width: 300px;
  height: 300px;
}
